.accordion {
    overflow-anchor: none;
}

.accordion>.card {
    overflow: hidden;
    margin-bottom: 10px;
    border: 0px;
    border-radius: 10px !important;
}

.accordion>.card:not(:last-of-type) {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.accordion>.card:not(:first-of-type) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.accordion>.card>.card-header {
    border-radius: 0;
    margin-bottom: -1px;
}

.card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-image: linear-gradient(#4b0083, #7C40A9);
}

.card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.mb-0,
.my-0 {
    margin-bottom: 0 !important;
}

.mt-0,
.my-0 {
    margin-top: 0 !important;
}

.btn-link {
    color: #fff !important;
}

.btn-link {
    font-weight: 400;
    color: #007bff;
    text-decoration: none;
}

.btn-link:hover {
    color: #0056b3;
    text-decoration: underline;
}

.btn-link:focus,
.btn-link.focus {
    text-decoration: underline;
}

.btn-link:disabled,
.btn-link.disabled {
    color: #6c757d;
    pointer-events: none;
}

.btn {
    font-weight: 600;
    background-color: transparent;
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
    color: #fff;
}

.collapse {
    background-color: #7C40A9;
    border-top: 1px dotted #fcfbf8;
}

.link {
    color: #d5d552;
}

.link:focus,
.link:hover {
    color: #fcfc0d;
}

.btn-link:hover {
    text-decoration: none  !important;
}

.headerTitle{
    font-weight: 600;
    font-size: 14px;
    color: #fff;
    padding: 0.375rem 0.75rem;
    vertical-align: super;
}
