@import url(https://fonts.googleapis.com/css?family=Codystar:300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

#root{
  background: #000;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.invest-main {
  /* margin-left: 10%;
  margin-right: 10%; */
  margin-bottom: 5%;
}

.header-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 0px 10px 10px;
}

.header1 {
  width: 195px;
  text-align: left;
  margin: unset;
}

.header-mid {
  width: auto;
  text-align: center;
  margin: unset;
}

.header-last {
  width: 130px;
  text-align: right;
  margin: 0px 10px;
}

.item-title {
  display: flex;
  font-size: 16px;
  flex-direction: column;
  position: relative;
  margin-bottom: 5px;
  transition: all 0.25s ease 0s;
  border: 2px solid transparent;
  background-color: transparent;
}

.item-title:hover {
  box-shadow: black 3px 3px;
  border: 2px solid black;
  background-color: #cab9ec;
  cursor: pointer;
}

.item-title-open {
  display: flex;
  font-size: 16px;
  flex-direction: column;
  position: relative;
  margin-bottom: 5px;
  transition: all 0.25s ease 0s;
  border: 2px solid black;
  background-color: #cab9ec;
}

.item-title-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.fDQfQP {
  font-weight: bold;
  width: 80px;
  min-width: auto;
  text-align: unset;
}

.lewZOv {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.gtgeyX {
  width: 20px;
  height: 20px;
  margin: 0px 5px 0px 0px;
}

.dEBvdT {
  font-weight: bold;
  /* width: 167px; */
  min-width: auto;
  text-align: unset;
}

.EhOEl {
  font-weight: bold;
  width: auto;
  min-width: 120px;
  text-align: right;
}

.eJfyVH span {
  font-size: 16px;
}

.opened1 {
  height: auto;
  transition: height 200ms linear 0s;
  overflow: hidden;
}

.closed1 {
  height: 0px;
  transition: height 200ms linear 0s;
  overflow: hidden;
}

.glfmCu {
  padding: 10px;
  cursor: auto;
}

.kYKazV {
  max-width: 30px;
  margin-left: 5px;
}




@media screen and (max-width: 1200px) {
  .resp {
    overflow: scroll !important;
  }
}

.blink_me {
  /* display: inline; */
  color: #b9925e;
  font-weight: 600;
  animation: blinker 3s linear infinite;
  font-size: 18px;
  margin-left: 10px;
}

@media screen and (max-width: 767px) {
  .blink_me {
    margin-left: 0px !important;
  }
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.note {
  position: relative;
  background: linear-gradient(120deg, #b9925e, #e9ad5d, #c57912);
  background-repeat: no-repeat;
  background-size: 80%;
  animation: animate 5s linear infinite;
  -webkit-background-clip: text;
  -webkit-text-fill-color: #b9925e;
}

.note2 {
  position: relative;
  background-repeat: no-repeat;
  background-size: 80%;
  animation: animate 5s linear infinite;
  -webkit-background-clip: text;
  -webkit-text-fill-color: #12e7b9;
}

@keyframes animate {
  0% {
    background-position: -500%;
  }

  100% {
    background-position: 500%;
  }
}

.neons {
  /* margin-top: 10rem; */
  text-align: center;
}

.neons h1 {
  font-size: 4rem;
  text-align: left;
  color: white;
  /* font-weight: bold;
  font-family: 'Codystar'; */
  /* -webkit-animation: glow 2s ease-in-out infinite alternate;
  -moz-animation: glow 2s ease-in-out infinite alternate;
  animation: glow 2s ease-in-out infinite alternate; */
}


@keyframes glow {
  from {
    color: #9F05C5;
    text-shadow: 0 0 10px #9570DD, 0 0 20px #9570DD, 0 0 30px #9570DD, 0 0 40px #9570DD, 0 0 50px #9570DD, 0 0 60px #9570DD, 0 0 70px #9570DD, 0 0 90px #9570DD;
  }

  to {
    color: gray;
    text-shadow: 0 0 20px #9570DD, 0 0 30px #9570DD, 0 0 40px #9570DD, 0 0 50px #9570DD, 0 0 60px #9570DD, 0 0 70px #9570DD, 0 0 80px #9570DD, 0 1 90px #9570DD;
  }
}

#headline {
  color: #4b0083;
}

#countdown-ul li {
  display: inline-block;
  font-size: 18px;
  list-style-type: none;
  padding: 1em;
  text-transform: uppercase;
  color: #9570DD;
}

#countdown-ul li span {
  display: block;
  font-size: 28px;
}

@media all and (max-width: 768px) {

  #countdown-ul li {
    font-size: 1.125rem;
    padding: .75rem;
  }

  #countdown-ul li span {
    font-size: 3.375rem;
  }
}

.pendingIds {
  margin: 10px;
  border: 1px dashed #9570DD
}

.headerLink {
  color: #9F05C5;
}

.headerLink:focus,
.headerLink:hover {
  color: #9F05C5;
}

.assets_balance {
  font-weight: 500;
  color: #48157f;
}

.socblock {
  position: fixed;
  display: flex;
  justify-content: center;
  flex-direction: column;
  right: 0;
  top: 0;
  width: 36px;
  height: 100vh;
  z-index: 1000;
}

.socwrap {
  position: relative;
  width: 30px;
  margin: 3px 0;
  height: 20px;
  line-height: 20px;
  padding-left: 6px;
  /* background: #f4f3f3; */
  border-radius: 20px 0 0 20px;
  transition: .3s;
  float: right;
}

.socwrap i {
  line-height: 1;
  font-weight: 400;
  margin: -3px 2px 0 0;
  font-size: 20px;
  color: #9570DD;
  position: relative;
  vertical-align: middle;
}

.socdesc {
  display: block;
  position: absolute;
  top: calc(50% - -5px);
  left: 6px;
  height: 10px;
  width: 25px;
  text-align: center;
  background: #fff;
  font-size: 7px;
  line-height: 10px;
  color: #000;
  border-radius: 0 0 0 8px;
  font-weight: 500;
}




h1 {
  color: white;
}

.border {
  border-bottom: 3px solid #898478
}

.border-box {
  border: 4px solid #fff;
}

header {
  background: linear-gradient(120deg, #161616, #161616, #161616);
}

.top-bg {
  background: linear-gradient(120deg, #161616, #161616, #161616);
  padding-top: 60px;
  padding-bottom: 5px;
}

.bg-color {
  background-color: #f3f3f3;
}

.margin-15 {
  margin: 15px auto;
}

.input-padding {
  padding: 10px 30px;
}

.button-padding {
  background: rgb(179 79 65);
  width: 242px;
  padding: 10px;
  color: #fff;
  border:rgb(179 79 65);
  border-radius: 10px;
}

.font-17 {
  font-size: 17px;
  font-weight: 500;
}

.white-font {
  color: #fff;
}

#link-white:hover {
  color: #fff;
}

.rounded-box {
  background-color: white;
  padding: 8px;
  border-radius: 10px;
  color:#000
}

.font_doller {
  font-weight: 600;
}

.dotted_box {
  margin-top: 3px;
  margin-bottom: 10px;
}
.accordion {
    overflow-anchor: none;
}

.accordion>.card {
    overflow: hidden;
    margin-bottom: 10px;
    border: 0px;
    border-radius: 10px !important;
}

.accordion>.card:not(:last-of-type) {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.accordion>.card:not(:first-of-type) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.accordion>.card>.card-header {
    border-radius: 0;
    margin-bottom: -1px;
}

.card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-image: linear-gradient(#4b0083, #7C40A9);
}

.card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.mb-0,
.my-0 {
    margin-bottom: 0 !important;
}

.mt-0,
.my-0 {
    margin-top: 0 !important;
}

.btn-link {
    color: #fff !important;
}

.btn-link {
    font-weight: 400;
    color: #007bff;
    text-decoration: none;
}

.btn-link:hover {
    color: #0056b3;
    text-decoration: underline;
}

.btn-link:focus,
.btn-link.focus {
    text-decoration: underline;
}

.btn-link:disabled,
.btn-link.disabled {
    color: #6c757d;
    pointer-events: none;
}

.btn {
    font-weight: 600;
    background-color: transparent;
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
    color: #fff;
}

.collapse {
    background-color: #7C40A9;
    border-top: 1px dotted #fcfbf8;
}

.link {
    color: #d5d552;
}

.link:focus,
.link:hover {
    color: #fcfc0d;
}

.btn-link:hover {
    text-decoration: none  !important;
}

.headerTitle{
    font-weight: 600;
    font-size: 14px;
    color: #fff;
    padding: 0.375rem 0.75rem;
    vertical-align: super;
}

